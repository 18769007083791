// A $( document ).ready() block.
$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
    window.token = $('meta[name="csrf-token"]').attr('content');
    //Add note
    $("#add_note").click(function () {
        addnote();
    });
    //Add image
    $("#add_image").click(function () {
        $("#input_upload_images").click();
    });
    $("#input_upload_images").on("change", function () {
        if($("#input_upload_images").val())
        {
            $('#loadcircle').removeClass('hidden');
            setTimeout(function () {
                $('#upload_imgur_form').submit();
            }, 300);
        }
    });
    $('#upload_imgur_form').submit(function () {
        var formData = new FormData($(this)[0]);
        $.ajax({
            url: '/upload_imgur',
            type: 'post',
            data: formData,
            async: false,
            success: function (returnData) {
                if (returnData.success) {
                    $("#canvas").append('<div class="note image animated zoomIn" theme="1" style="left:40px;top:90px;width:195px;height:180px;"><a class="pointer delete_note"><img src="images/remove.png"></a><img class="note_img" src="' + returnData.link + '"></div>');
                    scrollto();
                    updateui($("#canvas .note:last-child"));
                    $("#input_upload_images").val("");
                    $('#loadcircle').addClass('hidden');
                }
                else {
                    $('#loadcircle').addClass('hidden');
                    alert('upload fail');
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
        return false;
    });
    //Delete
    $("#canvas").on("click", ".delete_note", function () {
        deletenote($(this));
    });
    //Change background
    $(".change_bg").click(function () {
        changebackground($(this));
    });
});
function addnote() {
    if(($('#canvas .note:last-child').css('left') == '40px')&&($('#canvas .note:last-child').css('top') == '90px')&&($('#canvas .note:last-child').css('width') == '185px')&&($('#canvas .note:last-child').css('height') == '170px')&&($('#canvas .note:last-child .editable').text() == ''))
    {
        return false;
    }
    else
    {
        $("#canvas").append('<div class="note sticky animated zoomIn" theme="1" style="left:40px;top:90px;width:185px;height:170px;"><a class="pointer delete_note"><img src="images/remove.png"></a><div class="editable" onblur="enableui($(this))"></div></div>');
        scrollto();
        updateui($("#canvas .note:last-child"));
        if($("#canvas .note:last-child .editable"))
        {
            $("#canvas .note:last-child").click(function () {
                $(this).draggable('disable');
                $(this).children('.editable').focus();
            });
        }
        tinymce_load();
    }
}
function deletenote(note) {
    var $this = note;
    $this.next('.editable').focus();
    tinymce.remove("div.editable");
    window.getSelection().removeAllRanges();
    $this.blur();
    $this.parent().removeClass('zoomIn');
    $this.parent().addClass('zoomOut');
    setTimeout(function () {
        $this.parent().remove();
    }, 300);
}
function changebackground(bg) {
    var id = bg.attr('data-id');
    $("body").css("background", "url('../images/bg/bg" + id + ".jpg') repeat");
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        $('.navbar-toggle').click();
    }
}
function updateui(note) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        note.removeAttr('style');
    }
    else{
        note.draggable({
            scroll: true,
            scrollSensitivity: 5,
            stop: function () {
                var $this = note;
                tinymce.remove("div.editable");
                setTimeout(function () {
                    window.getSelection().removeAllRanges();
                    $this.blur();
                    tinymce_load();
                }, 69);
            }
        }).resizable({
            stop: function () {
                var $this = note;
                tinymce.remove("div.editable");
                setTimeout(function () {
                    window.getSelection().removeAllRanges();
                    $this.blur();
                    tinymce_load();
                }, 69);
            }
        });
    }
}

function enableui(note) {
    window.getSelection().removeAllRanges();
    try {
        note.parent().draggable('enable');
    }
    catch(err) {
        return false;
    }
}
function tinymce_load() {
    tinymce.init({
        language: 'en_GB',
        selector: 'div.editable',  // change this value according to your HTML
        inline: true,
        menubar: false,
        skin: 'charcoal',
        plugins: ['autoresize autolink lists paste notecolor emoticons textcolor colorpicker'],
        toolbar: ['styleselect bullist numlist','notecolor forecolor backcolor emoticons'],
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '' // Needed for 3.x
    });
}
function scrollto() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.navbar-toggle').click();
        $("html, body").delay(400).animate({
            scrollTop: $('#canvas .note:last-child').offset().top
        }, 1000);
    }
    else {
        $("html, body").delay(200).animate({
            scrollTop: $('body').offset().top,
            scrollLeft: $('body').offset().left
        }, 500);
    }
}